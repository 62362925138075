import { navigate } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'
import Player from '../components/epgPlayer'

const EpgPlayerPage = ({ channel }) => {
    const noChannelData = !channel || Object.keys(channel).length === 0
    const isClient = typeof window !== 'undefined'

    if (noChannelData && isClient) {
        navigate('/')
    }

    return <Player channel={channel} shouldAutoplay={true} shouldHideControls={false} />
}

const mapStateToProps = ({ channel }) => {
    return { channel }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const ConnectedEpgPlayerPage = connect(mapStateToProps, mapDispatchToProps)(EpgPlayerPage)

export default ConnectedEpgPlayerPage
